import React, { useCallback, useState } from 'react'
import { Box, Card, CardContent, Grid, Paper, Typography, Tabs, Tab, LinearProgress } from '@mui/material'

import { SearchBar, FlatButton, SubmitDialog } from '../UI/Components'
import { validatePoNumber, getBarcodePoItemsBasedOnPoNumber, getBarcodePoQuantityBasedOnPoAndItem, 
  getBarcodePoDetailsBasedOnPoAndItem, postBarcodePoDetails, getGeneratedBarcodeDetailsBasedOnPoAndItem } from './api/apiFunctions'
import useAuthenticationToken from '../Services/Authentication/useAuthenticationToken'
import { BarcodePoQuantity, BarcodePoDetails, PostPoDetails, BarcodeRollData, GeneratedBarcodeDetails } from './types'
import { roundNumberToSignificantDigits, convertNumberToLocaleString } from '../utils'
import { BarcodeDropdownMenu, FabricRollEntry, DisplayBarcodes, TabPanel, BarcodeRetreive } from './components'
import { AxiosError } from 'axios'
import SnackBarMessage from '../UI/Components/SnackBarMessage'
import StringValues from '../Providers/StringValues'

enum barcodeInitalValue {
  itemValue = 'SELECT ITEMCODE'
}

interface BarcodesProps {
  user : string,
  userPermission: Array<Object>,
  componentHeader : (headerName : string) => void
}

const Barcodes = (props: BarcodesProps) => {
  useAuthenticationToken()
  props.componentHeader('Barcodes')

  const {user, userPermission} = props

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [snackBarOpen, setSnackBarOpen] = useState(false)
  const [continueStatus, setContinueStatus] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogMessage, setDialogMessage] = useState<string>('')
  const [dataSubmitStatus, setDataSubmitStatus] = useState(false)
  const [displayBarcodeStatus, setDisplayBarcodeStatus] = useState(false)
  const [barcodePostDialogStatus, setBarcodePostDialogStatus] = useState(false)

  const [barcodePoQuantity, setBarcodePoQuantity] = useState<BarcodePoQuantity>()
  const [rollDetails, setRollDetails] = useState<BarcodeRollData[]>([])
  const [poDetails, setPoDetails] = useState<BarcodePoDetails>()

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [itemCode, setItemCode] = useState<string>('')
  const [itemDescription, setItemDescription] = useState<string>('')
  const [itemList, setItemList] = useState<string[]>([barcodeInitalValue.itemValue])
  const [disableControls, setDisableControls] = useState(false)
  const [selectedEntryDropDownValue, setSelectedEntryDropDownValue] = useState<string>(barcodeInitalValue.itemValue)

  const [tabState, setTabState] = useState(0)

  const [barcodeRetreiveSearchTerm, setBarcodeRetreiveSearchTerm] = useState<string>('')
  const [barcodeRetreiveItemCode, setBarcodeRetreiveItemCode] = useState<string>('')
  const [barcodeRetreiveItemList, setBarcodeRetreiveItemList] = useState<string[]>([barcodeInitalValue.itemValue])
  const [selectedBarcodeRetreiveDropDownValue, setSelectedBarcodeRetreiveDropDownValue] = useState<string>(barcodeInitalValue.itemValue)
  const [barcodeRetreiveDetails, setBarcodeRetreiveDetails] = useState<GeneratedBarcodeDetails>()
  const [barcodeRetreiveRollDetails, setBarcodeRetreiveRollDetails] = useState<BarcodeRollData[]>([])
  const [barcodeRetreivePoDetails, setBarcodeRetreivePoDetails] = useState<BarcodePoDetails>()
  const [selectedBarcodeList, setSelectedBarcodeList] = useState<BarcodeRollData[]>([])
  const [rePrintDisplayBarcodeStatus, setRePrintDisplayBarcodeStatus] = useState(false)

  const [supplierInvoiceNumber, setSupplierInvoiceNumber] = useState<string>('')

  /** Api call to validate PO and fetch list of item codes */
  const fetchPoItemCodeList = useCallback(async (searchTerm: string, tabIndex: number) => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    if (tabIndex === 0){
      setItemList([barcodeInitalValue.itemValue])
      setSelectedEntryDropDownValue(barcodeInitalValue.itemValue)
      setContinueStatus(false)
      setDataSubmitStatus(false)
      setDisableControls(false)
      setItemCode('')
      setItemDescription('')
      setDisplayBarcodeStatus(false)
    }

    if (tabIndex === 1){
      setBarcodeRetreiveItemList([barcodeInitalValue.itemValue])
      setSelectedBarcodeRetreiveDropDownValue(barcodeInitalValue.itemValue)
      setBarcodeRetreiveItemCode('')
      setBarcodeRetreiveDetails(undefined)
      setRePrintDisplayBarcodeStatus(false)
      setSelectedBarcodeList([])
    }
    
    try {
      await validatePoNumber(searchTerm)
      const barcodeItemCodeListResult = await getBarcodePoItemsBasedOnPoNumber(searchTerm)

      const itemList = barcodeItemCodeListResult.map(({ ITEMNO, ITEMDESC }) => {
        return `${ITEMNO} | ${ITEMDESC}`
      })
      itemList.unshift(barcodeInitalValue.itemValue)

      if (tabIndex === 0){
        setItemList(itemList) 
      } else {
        setBarcodeRetreiveItemList(itemList)
      }
      
      


      setIsError(false)
      setErrorMessage('')
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }

    setIsLoading(false)
  }, [searchTerm])

  /** Api call to fetch po quantity and details */
  const fetchPoQuantityAndDetails = useCallback(async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    setDataSubmitStatus(false)
    setIsError(false)
    setErrorMessage('')
    try {
      const poQuantityResults = await getBarcodePoQuantityBasedOnPoAndItem(searchTerm, itemCode)
      const poDetailsResults : BarcodePoDetails = await getBarcodePoDetailsBasedOnPoAndItem(searchTerm, itemCode)
      setPoDetails(poDetailsResults)
      setBarcodePoQuantity(poQuantityResults)
      setContinueStatus(true)
      setIsLoading(false)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setContinueStatus(false)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [searchTerm, itemCode])

  /** Api call to post po along with roll details */
  const postPoDetailsAndRollDetails = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    setDataSubmitStatus(false)
    setDisplayBarcodeStatus(false)
    try {
      const poDetailsResults : BarcodePoDetails = await getBarcodePoDetailsBasedOnPoAndItem(searchTerm, itemCode)
      const {ocNumber, customer, styleNumber, styleDescription} = poDetailsResults

      const rollQuantityDataList = rollDetails.map((rollObject)=>{
        const {roll, length, supplierRollNo, fabricWidth, cuttableWidth} = rollObject
        return {roll, length, supplierRollNo, fabricWidth, cuttableWidth}
      })

      const postData = {
        OC: ocNumber, 
        custName: customer,
        styleNo: styleNumber,
        styleDesc: styleDescription,
        itemNo: itemCode,
        itemDesc: itemDescription,
        name: user,
        invoiceNumber: supplierInvoiceNumber,
        tableData: rollQuantityDataList
      }

      
      await postBarcodePoDetails(searchTerm, postData as PostPoDetails)
      const poQuantityResults = await getBarcodePoQuantityBasedOnPoAndItem(searchTerm, itemCode)

      poDetailsResults.itemDescription = `${itemCode} ${itemDescription}`
      setPoDetails(poDetailsResults)
      setBarcodePoQuantity(poQuantityResults)

      setDisplayBarcodeStatus(true)
      setDataSubmitStatus(true)
      setIsLoading(false)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [searchTerm, itemCode, rollDetails])


  /** Api call to fetch generated barcode based on po number and item Code */
  const fetchGeneratedBarcode = useCallback(async () => {
    if (isLoading) {
      return
    }

    setIsLoading(true)
    setIsError(false)
    setErrorMessage('')
    setSelectedBarcodeList([])
    setRePrintDisplayBarcodeStatus(false)
    try {
      const barcodeRetreiveResults = await getGeneratedBarcodeDetailsBasedOnPoAndItem(barcodeRetreiveSearchTerm, barcodeRetreiveItemCode)
      setBarcodeRetreiveDetails(barcodeRetreiveResults)
      
      setIsLoading(false)
    } catch (err) {
      if (err instanceof AxiosError || err instanceof Error) {
        setSnackBarOpen(true)
        setIsError(true)
        setErrorMessage(err.message)
      } else {
        throw err
      }
    }
    setIsLoading(false)
  }, [barcodeRetreiveSearchTerm, barcodeRetreiveItemCode])

  /**Handle entry search bar changes */
  const handleSearchBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchTerm(event.target.value)
  }

  /**Handle barcode retreive search bar changes */
  const handleBarcodeRetreiveBarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBarcodeRetreiveSearchTerm(event.target.value)
  }

  /**Handle entry search bar finder button click */
  const handleSearchBarButtonClick = () => {
    fetchPoItemCodeList(searchTerm, tabState)
  }

  /**Handle barcode reterive search bar finder button click */
  const handleBarcodeRetriveSearchBarButtonClick = () => {
    fetchPoItemCodeList(barcodeRetreiveSearchTerm, tabState)
  }

  /**Handle entry dropdown menu value change */
  const handleDropdownMenuChange = (value: string) => {
    setSelectedEntryDropDownValue(value)

    if (value === barcodeInitalValue.itemValue){
      setItemCode('')
      setItemDescription('')
      return
    }
    
    const result = value.split('|').map(element => element.trim())
    setItemCode(result[0])
    setItemDescription(result[1])
  }

  /**Handle barcode retreive dropdown menu value change */
  const handleBarcodeRetreiveDropdownMenuChange = (value: string) => {
    setSelectedBarcodeRetreiveDropDownValue(value)

    if (value === barcodeInitalValue.itemValue){
      setBarcodeRetreiveItemCode('')
      return
    }

    const result = value.split('|').map(element => element.trim())
    setBarcodeRetreiveItemCode(result[0])
  }

  /**Handle entry tab continue button click */
  const handleContinueButtonClick = () => {
    
    if (searchTerm === '' || searchTerm === null || searchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (itemCode === '' || itemCode === null || itemCode === undefined || itemCode === barcodeInitalValue.itemValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.itemCodeValidationMessage)
      return
    }

    fetchPoQuantityAndDetails()
  }

  /**Handle barcode retreive tab continue button click */
  const handleBarcodeRetreiveContinueButtonClick = () => {
    
    if (barcodeRetreiveSearchTerm === '' || barcodeRetreiveSearchTerm === null || barcodeRetreiveSearchTerm === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (barcodeRetreiveItemCode === '' || barcodeRetreiveItemCode === null || 
        barcodeRetreiveItemCode === undefined || barcodeRetreiveItemCode === barcodeInitalValue.itemValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.itemCodeValidationMessage)
      return
    }

    fetchGeneratedBarcode()
  }

  /**Get order quantity based on API response */
  const getOrderQuantity = () => {
    if (barcodePoQuantity !== null && barcodePoQuantity !== undefined) {
      return barcodePoQuantity.ordQty
    }
    return 0
  }

  /**Get current quantity based on API response */
  const getCurrentQuantity = () => {
    if (barcodePoQuantity !== null && barcodePoQuantity !== undefined) {
      return barcodePoQuantity.currQty
    }
    return 0
  }

  /**Calculate remaining quantity based on exceptional quantity allowed
   * if buyer is old navy exception value is 3% or by default 2% on order quantity on order quantity
   * ensure in future exception percent condition repeat this 
   * need to recode from static to dynamic to ensure process flow with dynamic declaration in this function
   * exceptional quantity  = 2% / 3% of orderQuanity + 50 meters 
   */
  const getRemainingQuantity = () => {
    if (barcodePoQuantity !== null && barcodePoQuantity !== undefined) {

      let exceptionalOrderQuantity = 0
      if (poDetails?.customer.toUpperCase().includes('OLD') && poDetails?.customer.toUpperCase().includes('NAVY')){
        exceptionalOrderQuantity = (barcodePoQuantity.ordQty + ((barcodePoQuantity.ordQty / 100) * 3))
      } else {
        exceptionalOrderQuantity = (barcodePoQuantity.ordQty + ((barcodePoQuantity.ordQty / 100) * 2) + 50)
      }
      
      return (exceptionalOrderQuantity - barcodePoQuantity.currQty)
    }
    return 0
  }

  /**Handle snack Bar close 
   * @param {boolean} value The status of snack bar
   */
  const handleSnackBarClose = (value: boolean) => {
    setSnackBarOpen(value)
  }

  /**Function to validate entered roll length 
   * @param {BarcodeRollData[]} rollLengthList The entered roll details
   * @return {boolean}
   */
  const isRollLengthListValid = (rollLengthList: BarcodeRollData[]): boolean => {
    return rollLengthList.map(rollLengthObject => {
      const { supplierRollNo, length } = rollLengthObject
      if (supplierRollNo === "" || supplierRollNo === null || length <= 0 || isNaN(length)) {
        return false
      }
      if (isRollNoRepeated(supplierRollNo, rollLengthList)) {
        return false
      }
      return true
    }).reduce((acc, curr) => {
      return acc && curr
    }, true)
  }

  /**Function to validate entered roll number 
   * @param {string} compareToRoll Roll number to compare
   * @param {BarcodeRollData[]} rollListArray List of roll details
   * @return {boolean}
   */
  const isRollNoRepeated = (compareToRoll: string, rollListArray: BarcodeRollData[]): boolean => {
    let rollRepeatCount = 0
    rollListArray.map(rollLengthObject => {
      const { supplierRollNo } = rollLengthObject
      if (supplierRollNo.toUpperCase() === compareToRoll.toUpperCase()) {
        rollRepeatCount++
      }
      return null
    })
    if (rollRepeatCount > 1) {
      return true
    }
    return false
  }

  /**Function to validate allowed fabric quantity
   * if buyer is old navy exception value is 3% or by default 2%
   * ensure in future exception percent condition repeat this 
   * need to recode from static to dynamic to ensure process flow with dynamic declaration in this function
   * @param {BarcodeRollData[]} rollLengthList The entered roll details
   * @return {boolean}
   */
  const isFabricQuantityAllowed = (rollLengthList: BarcodeRollData[]): boolean => {

    const totalEnteredFabricLength = rollLengthList.reduce((acc, curr) => {
      return acc + curr.length
    }, 0)
   
    
    let exceptionQuantity = 0
    if (poDetails?.customer.toUpperCase().includes('OLD') && poDetails?.customer.toUpperCase().includes('NAVY')){
      exceptionQuantity = ((getOrderQuantity() / 100) * 3)
    } else {
      exceptionQuantity = ((getOrderQuantity() / 100) * 2) + 50
    }
    
    const allowedOrderQuantity = getOrderQuantity() + exceptionQuantity

    const currentQuantity = getCurrentQuantity() + totalEnteredFabricLength

    if (currentQuantity > allowedOrderQuantity){
      return false
    }

    return true
  }

  /**This function is responsible to validate and post entered roll details
   * @param {BarcodeRollData[]}rollQuantitiesDetails The enetered roll details
   */
  const validateAndPostBarcodeQuantities = (supplierInvoiceNumber: string, rollQuantitiesDetails : BarcodeRollData[]) => {
    setDataSubmitStatus(false)

    if (searchTerm === '' || searchTerm === null || searchTerm === undefined || searchTerm === barcodeInitalValue.itemValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.searchTermValidationMessage)
      return
    }

    if (itemCode === '' || itemCode === null || itemCode === undefined || itemCode === barcodeInitalValue.itemValue){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.itemCodeValidationMessage)
      return
    }

    if (supplierInvoiceNumber === '' || supplierInvoiceNumber === null || supplierInvoiceNumber === undefined){
      setSnackBarOpen(true)
      setIsError(false)
      setErrorMessage(StringValues.supplierInvoiceNumberValidationMessage)
      return
    }

    if (isRollLengthListValid(rollQuantitiesDetails) === false) {
      setSnackBarOpen(true)
      setErrorMessage(StringValues.barcodeRollLengthMessage)
      return
    }

    if (isFabricQuantityAllowed(rollQuantitiesDetails) === false){
      setSnackBarOpen(true)
      setErrorMessage(StringValues.barcodeRollLengthValidationMessage)
      return
    }

    setBarcodePostDialogStatus(true)
    setSupplierInvoiceNumber(supplierInvoiceNumber)
    setRollDetails(rollQuantitiesDetails)
    setDialogMessage(StringValues.barcodeConfirmationMessage)
    setDialogOpen(true)
    setDisableControls(true)
  }

  /**Function responsible to handle confirmation dialog on close
   * @param {boolean} value The dialog confirmation status
   */
  const handleBarcodeDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){
      postPoDetailsAndRollDetails()
    }
  }

  /** Handle snack bar display */
  const handleDisplayBarcodeSnackBar = (message: string) => {
    setDataSubmitStatus(false)
    setSnackBarOpen(true)
    setErrorMessage(message)
  }

  /** This function is responsible to display success message on snack bar 
   * based on entered roll details post
   */
  const handleBarcodePrint = () => {
    setDataSubmitStatus(true)
    setSnackBarOpen(true)
    setErrorMessage(StringValues.barcodePrintSuccessMessage)
  }

  /**This function responsible for confirmation dialog to print/save 
   * barcode before  moving to roll entry screen
   */
  const handleBackDialog = () => {
    setBarcodePostDialogStatus(false)
    setDialogMessage(StringValues.barcodePrintBackConfirmationMessage)
    setDialogOpen(true)
    
  }

  /**This function is responsible to handle  barcode print dialog close
   *@param {boolean} value The status of dialog 
   */
  const handleBarcodePrintDialogClose = (value: boolean) => {
    setDialogOpen(false)
    if (value === true){
      setDisableControls(false)
      if (tabState === 0){
        setDisplayBarcodeStatus(false)
      }
      if (tabState === 1){
        setRePrintDisplayBarcodeStatus(false)
      }
    }
  }


  /**This function is responsible to validate and reprint barcodes
   * @param {BarcodeRollData[]}rollQuantitiesDetails The enetered roll details
   */
  const validateAndRePrintBarcodes = (rollQuantitiesDetails : BarcodeRollData[]) => {

    if (rollQuantitiesDetails.length === 0) {
      setSnackBarOpen(true)
      setErrorMessage(StringValues.barcodeRePrintValidateMessage)
      return
    }

    setSelectedBarcodeList(rollQuantitiesDetails)
    const {ocNumber, customer, styleNumber, styleDescription, itemDescription} = barcodeRetreiveDetails as GeneratedBarcodeDetails
    const itemCodeWithDescription = `${barcodeRetreiveItemCode} ${itemDescription}`
    setBarcodeRetreivePoDetails({ocNumber, customer, styleNumber, styleDescription, itemDescription: itemCodeWithDescription})
    setBarcodeRetreiveRollDetails(rollQuantitiesDetails)
    setRePrintDisplayBarcodeStatus(true)
  }

  return (
    <React.Fragment>
      {
        dataSubmitStatus === true ? <SnackBarMessage message = {StringValues.submitSuccessMessage} successStatus = {true} 
          open = {snackBarOpen} onClose={handleSnackBarClose}/> 
          : <SnackBarMessage message = {errorMessage} successStatus = {false} open = {snackBarOpen} onClose={handleSnackBarClose}/>
      }

      <SubmitDialog 
        onClose={ barcodePostDialogStatus === true ? handleBarcodeDialogClose : handleBarcodePrintDialogClose} 
        open = {dialogOpen} 
        submitStatus = {false} 
        dialogMessage={dialogMessage} />

      <Grid container sx={{
        height: '100vh'
      }}>
        <Grid item xs={3}
          sx={{
            paddingLeft: 2,
            paddingTop: 2,
            paddingBottom: 2
          }}>
          <Box sx={{
            width: '100%',
            height: '100%',
            backgroundColor: 'surface.dark'
          }}>
            
            <Paper elevation={8} sx={{
              height: '100%'
            }}>

              <Tabs value={tabState} onChange={(e: React.SyntheticEvent, newValue: number) => {
                setTabState(newValue)
              }} textColor='secondary' TabIndicatorProps={{
                style: {
                  backgroundColor: 'alpha.light'
                }
              }}>
                <Tab sx={{color: 'alpha.light'}} label="ENTRY" />
                <Tab sx={{color: 'alpha.light'}} label="RETRIEVE BARCODE" />
              </Tabs>

              <Box sx={{
                paddingTop: 2
              }}> 
                <TabPanel value={tabState} index={0}>
                  <Box sx={{paddingTop: 2}}> 
                    <SearchBar value={searchTerm} onChange={handleSearchBarChange} 
                      onButtonClick={handleSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
                      inputLabel = {StringValues.poLabel} width = {'80%'} marginLeft={15}/>
                  </Box>
                  <Box sx={{ paddingTop: 2, marginLeft: 1}}>
                    <BarcodeDropdownMenu onChange={handleDropdownMenuChange} menuValues = {itemList} disableControlsStatus = {disableControls} value = {selectedEntryDropDownValue}/>  
                  </Box>

                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handleContinueButtonClick} disableControlsStatus = {disableControls}/>
                  </Box>
                </TabPanel>

                <TabPanel value={tabState} index={1}>
                  <Box sx={{paddingTop: 2}}> 
                    <SearchBar value={barcodeRetreiveSearchTerm} onChange={handleBarcodeRetreiveBarChange} 
                      onButtonClick={handleBarcodeRetriveSearchBarButtonClick} error={isError} errorMessage={errorMessage} 
                      inputLabel = {StringValues.poLabel} width = {'80%'} marginLeft={15}/>
                  </Box>

                  <Box sx={{ paddingTop: 2, marginLeft: 1}}>
                    <BarcodeDropdownMenu onChange={handleBarcodeRetreiveDropdownMenuChange} menuValues = {barcodeRetreiveItemList} disableControlsStatus = {false} value = {selectedBarcodeRetreiveDropDownValue}/>
                  </Box>

                  <Box display="flex"
                    justifyContent="center"
                    alignItems="center" sx={{ paddingTop: 3 }}>
                    <FlatButton label='CONTINUE' onClick={handleBarcodeRetreiveContinueButtonClick} disableControlsStatus = {false}/>
                  </Box>

                </TabPanel>
              </Box> 
            </Paper>
              
          </Box>
        </Grid>
        <Grid item xs={9} sx={{
          paddingLeft: 2,
          paddingRight: 2,
          paddingTop: 2,
          paddingBottom: 2
        }}>
          {
            (isLoading) ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 9999,
                }}
              >
                <LinearProgress sx={{ width: '50%' }}/>
              </Box>
            ) : (
              <Box sx={{
                width: '100%',
                height: '100%',
                bgcolor: 'primary.pane',
                backgroundColor: 'surface.dark'
              }}>
                {
                  continueStatus === true && tabState === 0 ? (
                    <React.Fragment>
                      <Box sx={{
                        padding: 2,
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 2
                      }}>
                        <Card sx={{
                          flex: 1
                        }}>
                          <CardContent>
                            <Typography variant='h6' color={'alpha.light'}>
                              {`${convertNumberToLocaleString(roundNumberToSignificantDigits(getOrderQuantity(), 2))} Meters`}
                            </Typography>
                          </CardContent>
                          <CardContent>
                            <Typography variant='body1'>
                              ORDER QUANTITY
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card sx={{
                          flex: 1
                        }}>
                          <CardContent>
                            <Typography variant='h6' color={'alpha.light'}>
                              {`${convertNumberToLocaleString(roundNumberToSignificantDigits(getCurrentQuantity(), 2))} Meters`}
                            </Typography>
                          </CardContent>
                          <CardContent>
                            <Typography variant='body1'>
                              CURRENT QUANTTY
                            </Typography>
                          </CardContent>
                        </Card>
                        <Card sx={{
                          flex: 1
                        }}>
                          <CardContent>
                            <Typography variant='h6' color={'alpha.light'}>
                              {`${convertNumberToLocaleString(roundNumberToSignificantDigits(getRemainingQuantity(), 2))} Meters`}
                            </Typography>
                          </CardContent>
                          <CardContent>
                            <Typography variant='body1'>
                              REMAINING
                            </Typography>
                          </CardContent>
                        </Card>
                      </Box>
                      <Box sx={{
                        paddingLeft: 2,
                        paddingRight: 2
                      }}>
                        {
                          displayBarcodeStatus === true
                            ? <DisplayBarcodes poDetails={poDetails as BarcodePoDetails} rollData={rollDetails} 
                              userPermissions={userPermission} handleSnackBar={handleDisplayBarcodeSnackBar} 
                              handleSuccessBarcodePrint={handleBarcodePrint} handleBackDialog={handleBackDialog}></DisplayBarcodes>
                            : <FabricRollEntry submitOrder={validateAndPostBarcodeQuantities} handleDisplayBarcodeSnackBar={handleDisplayBarcodeSnackBar}></FabricRollEntry>
                        }
                        
                      </Box>
                    </React.Fragment>
                  ) : 
                    barcodeRetreiveDetails !== null && barcodeRetreiveDetails !== undefined && tabState === 1
                      ? rePrintDisplayBarcodeStatus === true 
                        ? <DisplayBarcodes poDetails={barcodeRetreivePoDetails as BarcodePoDetails} rollData={barcodeRetreiveRollDetails} 
                          userPermissions={userPermission} handleSnackBar={handleDisplayBarcodeSnackBar} 
                          handleSuccessBarcodePrint={handleBarcodePrint} handleBackDialog={handleBackDialog}></DisplayBarcodes>
                        : <BarcodeRetreive barcodeDetails={barcodeRetreiveDetails} barcodeRePrintSubmit={validateAndRePrintBarcodes} selectedBarcode={selectedBarcodeList}/>
                      : null
                  
                }
              </Box>
            )
          }
        </Grid>
      </Grid>
    </React.Fragment >
  )
}

export default Barcodes