import React, { useEffect, useState } from "react"
import { Box, Skeleton } from '@mui/material'
import { Routes, Route } from 'react-router-dom'

import { SideMenu, FixedSideMenu } from './UI/Components'
import CuttingDashboard from './CuttingDashboard'
import { useAuth0 } from '@auth0/auth0-react'
import ProtectedRoute from './Services/Authentication/ProtectedRoute'
import rules from './Services/Authentication/rbac-rules'
import FabricDashboard from './FabricDashboard'
import SewingDashboard from "./SewingDashboard"
import WarehouseDashboard from "./WarehouseDashboard"
import RachManageDashboard from "./RackManageDashboard"
import ManageOrdersDashboard from "./ManageOrdersDashboard"
import OcDetails from "./OcDetails"
import Barcodes from "./Barcodes"
import JobWorkDashboard from "./JobWorkDashboard"
import OverViewDashboard from "./OverViewDashboard"
import OcTrackerDashboard from "./OcTrackerDashboard"
import FinishingDashboard from "./FinishingDashboard"
import ProductLabel from "./ProductLabel"
import HomeLottieLogo from "./Home"
import { Typography } from '@mui/material'

function App() {
  const [userPermissions, setUserPermissions] = useState([])
  const [userName, setUserName] = useState<string>('')
  const { loginWithRedirect, isAuthenticated, isLoading, user, logout } = useAuth0()
  const [header, setHeader] = useState<string>('')

  useEffect(() => {
    if (user) {
      setUserPermissions(user[`${process.env.REACT_APP_ROLE_NAMESPACE}/user_authorization`]["permissions"])
      setUserName(user['name'] as string)
    }
  }, [user])
  if (isLoading) {
    //  Return this skeleton until the user is authenticated
    return (
      <Skeleton variant='rectangular'>
        <Box sx={{ width: '200px', height: '100vh' }} />
      </Skeleton>
    )
  }

  if (!isAuthenticated) {
    loginWithRedirect()
    return <div></div>
  }

  const componentHeader = (headerName: string) => {
    setHeader(headerName)
  }
  /**
   * To understand why the private routes are rendered this way, see this blog: https://dev.to/iamandrewluca/private-route-in-react-router-v6-lg5
   * and this SO answer: https://stackoverflow.com/questions/69864165/error-privateroute-is-not-a-route-component-all-component-children-of-rou
   */
  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: 1,
          m: 1
        }}>
        <FixedSideMenu userPermissions={userPermissions} logout={logout} />
        <SideMenu userPermissions={userPermissions} logout={logout} />
        <Typography variant='h6' paddingTop={1} color={'alpha.main'}>
          {header}
        </Typography>
      </Box>


      <Box sx={{
        paddingTop: 1,
        paddingLeft: 8
      }}>
        <Routes>
          <Route path='/' element={
            <HomeLottieLogo componentHeader={componentHeader} />
          } />

          <Route path='/Overview' element={
            <ProtectedRoute path='/Overview' userPermissions={userPermissions} action={rules.OverView}>

              <OverViewDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/OcTracker' element={
            <ProtectedRoute path='/OcTracker' userPermissions={userPermissions} action={rules.OcTracker}>

              <OcTrackerDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />



          <Route path='/fabric' element={
            <ProtectedRoute path='/fabric' userPermissions={userPermissions} action={rules.FabricAnalytics}>

              <FabricDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />


          <Route path='/rack_manage' element={
            <ProtectedRoute path='/rack_manage' userPermissions={userPermissions} action={rules.RackmanageAnalytics}>

              <RachManageDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          {/* <Route path='/shrinkage' element={
            <ProtectedRoute path='/shrinkage' userPermissions={userPermissions} action={rules.ShrinkageAnalytics}>

              <FabricDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } /> */}

          <Route path='/cutting' element={
            <ProtectedRoute path='/cutting' userPermissions={userPermissions} action={rules.CuttingAnalytics}>

              <CuttingDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />


          <Route path='/sewing' element={
            <ProtectedRoute path='/sewing' userPermissions={userPermissions} action={rules.SewingAnalytics}>

              <SewingDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/warehouse' element={
            <ProtectedRoute path='/warehouse' userPermissions={userPermissions} action={rules.WarehouseAnalytics}>

              <WarehouseDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/finishing' element={
            <ProtectedRoute path='/finishing' userPermissions={userPermissions} action={rules.WarehouseAnalytics}>

              <FinishingDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/ManageOrders' element={
            <ProtectedRoute path='/ManageOrders' userPermissions={userPermissions} action={rules.ManageOrders}>

              <ManageOrdersDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/OcDetails' element={
            <ProtectedRoute path='/OcDetails' userPermissions={userPermissions} action={rules.OcDetails}>

              <OcDetails componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/Barcode' element={
            <ProtectedRoute path='/Barcode' userPermissions={userPermissions} action={rules.Barcodes}>

              <Barcodes user={userName} userPermission={userPermissions} componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/Jobwork' element={
            <ProtectedRoute path='/Jobwork' userPermissions={userPermissions} action={rules.JobworkOrder}>

              <JobWorkDashboard componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

          <Route path='/productlabel' element={
            <ProtectedRoute path='/productlabel' userPermissions={userPermissions} action={rules.ProductQr}>

              <ProductLabel componentHeader={componentHeader} />
            </ProtectedRoute>
          } />

        </Routes>
      </Box>
    </React.Fragment>
  )
}

export default App
