import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Drawer,
  List,
  Divider,
  Typography,
  Link,
  IconButton,
} from "@mui/material"
import SpeedIcon from "@mui/icons-material/Speed"
import ReceiptLongIcon from "@mui/icons-material/ReceiptLongOutlined"
import ContentCutIcon from "@mui/icons-material/ContentCut"
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumberOutlined"
import AllInboxSharpIcon from '@mui/icons-material/AllInboxSharp'
import AssignmentIcon from "@mui/icons-material/AssignmentOutlined"
import WarehouseIcon from "@mui/icons-material/WarehouseOutlined"
import ManageSearchIcon from "@mui/icons-material/ManageSearch"
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner"
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from "@mui/icons-material/Logout"
import OutboundOutlinedIcon from "@mui/icons-material/OutboundOutlined"
import MenuIcon from "@mui/icons-material/Menu"
import Can from "./Can"
import rules from "../../Services/Authentication/rbac-rules"
import { ReactComponent as OCIcon } from "../Icon/ocIcon.svg"
import { ReactComponent as ProductLabelLogo } from "../Icon/productLabelLogo.svg"
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined"

const CustomListItemIcon = (props: {
  children:
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactFragment
}) => {
  return (
    <ListItemIcon
      sx={{
        minWidth: "40px",
        color: "white",
      }}
    >
      {props.children}
    </ListItemIcon>
  )
}

const SideMenu = (props: { userPermissions: Array<Object>; logout: any }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<string | null>(null)

  const handleMenuItemClick = (item: string) => {
    setSelectedItem(item)
    setIsDrawerOpen(false)
  }

  return (
    <Box>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="logo"
        onClick={() => setIsDrawerOpen(true)}
        sx={{ paddingLeft: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="left"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        PaperProps={{
          sx: {
            backgroundColor: "alpha.main",
            color: "white",
            minWidth: "200px",
          },
        }}
      >
        <List>
          <Divider
            component={"li"}
            sx={{
              border: "none",
            }}
          />

          <Link
            component={RouterLink}
            to="/"
            underline="none"
            sx={{ color: "white" }}
          >
            <ListItem button onClick={() => handleMenuItemClick('')}>
              <Typography
                variant="h4"
                sx={{
                  marginLeft: 2,
                  marginTop: 4,
                }}
              >
                Fabtrakr
              </Typography>
            </ListItem>
          </Link>

          <li>
            <Typography
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 1,
                marginTop: 4,
              }}
            >
              MANAGEMENT
            </Typography>
          </li>

          <Can
            userPermissions={props.userPermissions}
            action={rules.OverView}
            yes={() => (
              <Link
                component={RouterLink}
                to="Overview"
                sx={{ color: "white" }}
              >
                <ListItem button onClick={() => handleMenuItemClick('Overview')}
                  sx={{ backgroundColor: selectedItem === 'Overview' ? '#5a81bc' : 'transparent' }}
                >
                  <CustomListItemIcon>
                    <SpeedIcon />
                  </CustomListItemIcon>
                  <ListItemText primary="Overview" />
                </ListItem>
              </Link>
            )}
            no={() => null}
            isValidateStrict={true}
          />

          <Can
            userPermissions={props.userPermissions}
            action={rules.OcTracker}
            yes={() => (
              <Link
                component={RouterLink}
                to="OcTracker"
                sx={{ color: "white" }}
              >
                <ListItem button onClick={() => handleMenuItemClick('OcTracker')}
                  sx={{ backgroundColor: selectedItem === 'OcTracker' ? '#5a81bc' : 'transparent' }}
                >
                  <CustomListItemIcon>
                    <OCIcon />
                  </CustomListItemIcon>
                  <ListItemText primary="OC Tracker" />
                </ListItem>
              </Link>
            )}
            no={() => null}
            isValidateStrict={true}
          />

          <Divider
            component={"li"}
            sx={{
              border: "none",
            }}
          />

          {/**Departments side menu  */}
          <Can
            userPermissions={props.userPermissions}
            action={rules.DepartmentsTitle}
            yes={() => (
              <li>
                <Typography
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 3,
                  }}
                >
                  DEPARTMENTS

                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.FabricManagement}
                    yes={() => (
                      <li>
                        <Typography
                          sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 3,
                          }}
                        >  <CustomListItemIcon>
                            <ReceiptLongIcon />
                          </CustomListItemIcon>Fabric Management
                          <Can
                            userPermissions={props.userPermissions}
                            action={rules.FabricAnalytics}
                            yes={() => (
                              <Link
                                component={RouterLink}
                                to="fabric"
                                sx={{ color: "white" }}
                              >
                                <ListItem button onClick={() => handleMenuItemClick('fabric')}
                                  sx={{ backgroundColor: selectedItem === 'fabric' ? '#5a81bc' : 'transparent' }}
                                >
                                  <CustomListItemIcon>
                                    <ReceiptLongIcon />
                                  </CustomListItemIcon>
                                  <ListItemText primary="Fabric" />
                                </ListItem>
                              </Link>
                            )}
                            no={() => null}
                            isValidateStrict={true}
                          />

                          <Can
                            userPermissions={props.userPermissions}
                            action={rules.RackmanageAnalytics}
                            yes={() => (
                              <Link
                                component={RouterLink}
                                to="rack_manage"
                                sx={{ color: "white" }}
                              >
                                <ListItem button onClick={() => handleMenuItemClick('rack_manage')}
                                  sx={{ backgroundColor: selectedItem === 'rack_manage' ? '#5a81bc' : 'transparent' }}
                                >
                                  <CustomListItemIcon>
                                    <AllInboxSharpIcon />
                                  </CustomListItemIcon>
                                  <ListItemText primary="Rack Manage" />
                                </ListItem>
                              </Link>
                            )}
                            no={() => null}
                            isValidateStrict={true}
                          />
                        </Typography>
                      </li>
                    )}
                    no={() => null}
                    isValidateStrict={false}
                  />


                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.CuttingAnalytics}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="cutting"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('cutting')}
                          sx={{ backgroundColor: selectedItem === 'cutting' ? '#5a81bc' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <ContentCutIcon />
                          </CustomListItemIcon>
                          <ListItemText primary="Cutting" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.SewingAnalytics}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="sewing"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('sewing')}
                          sx={{ backgroundColor: selectedItem === 'sewing' ? '#5a81bc' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <ConfirmationNumberIcon />
                          </CustomListItemIcon>
                          <ListItemText primary="Sewing" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.Finishing}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="warehouse"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('warehouse')}
                          sx={{ backgroundColor: selectedItem === 'warehouse' ? '#5a81bc' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <WarehouseIcon />
                          </CustomListItemIcon>
                          <ListItemText primary="Warehouse" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.Finishing}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="finishing"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('finishing')}
                          sx={{ backgroundColor: selectedItem === 'finishing' ? 'alpha.light' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <Inventory2OutlinedIcon />
                          </CustomListItemIcon>
                          <ListItemText primary="Finishing" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />

                  <Divider
                    component={"li"}
                    sx={{
                      border: "none",
                    }}
                  />
                </Typography>
              </li>
            )}
            no={() => null}
            isValidateStrict={false}
          />

          {/**Products side menu  */}

          <Can
            userPermissions={props.userPermissions}
            action={rules.ProductsTitle}
            yes={() => (
              <li>
                <Typography
                  sx={{
                    paddingLeft: 2,
                    paddingRight: 2,
                    paddingTop: 3,
                  }}
                >
                  PRODUCTS
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.FabricManagement}
                    yes={() => (
                      <li>
                        <Typography
                          sx={{
                            paddingLeft: 2,
                            paddingRight: 2,
                            paddingTop: 3,
                          }}
                        >  <CustomListItemIcon>
                            <ManageAccountsIcon />
                          </CustomListItemIcon> Products Management
                          <Can
                            userPermissions={props.userPermissions}
                            action={rules.ManageOrders}
                            yes={() => (
                              <Link
                                component={RouterLink}
                                to="ManageOrders"
                                sx={{ color: "white" }}
                              >
                                <ListItem button onClick={() => handleMenuItemClick('ManageOrders')}
                                  sx={{ backgroundColor: selectedItem === 'ManageOrders' ? 'alpha.light' : 'transparent' }}
                                >
                                  <CustomListItemIcon>
                                    <AssignmentIcon />
                                  </CustomListItemIcon>
                                  <ListItemText primary="Manage Orders" />
                                </ListItem>
                              </Link>
                            )}
                            no={() => null}
                            isValidateStrict={true}
                          />

                          <Can
                            userPermissions={props.userPermissions}
                            action={rules.RackmanageAnalytics}
                            yes={() => (
                              <Link
                                component={RouterLink}
                                to="OcDetails"
                                sx={{ color: "white" }}
                              >
                                <ListItem button onClick={() => handleMenuItemClick('OcDetails')}
                                  sx={{ backgroundColor: selectedItem === 'OcDetails' ? 'alpha.light' : 'transparent' }}
                                >
                                  <CustomListItemIcon>
                                    <ManageSearchIcon />
                                  </CustomListItemIcon>
                                  <ListItemText primary="OC Grid Quantity" />
                                </ListItem>
                              </Link>
                            )}
                            no={() => null}
                            isValidateStrict={true}
                          />
                        </Typography>
                      </li>
                    )}
                    no={() => null}
                    isValidateStrict={false}
                  />
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.Barcodes}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="Barcode"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('Barcode')}
                          sx={{ backgroundColor: selectedItem === 'Barcode' ? 'alpha.light' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <QrCodeScannerIcon />
                          </CustomListItemIcon>
                          <ListItemText primary="Barcode" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.JobworkOrder}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="Jobwork"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('Jobwork')}
                          sx={{ backgroundColor: selectedItem === 'Jobwork' ? 'alpha.light' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <OutboundOutlinedIcon />
                          </CustomListItemIcon>
                          <ListItemText primary="Jobwork" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />
                  <Can
                    userPermissions={props.userPermissions}
                    action={rules.ProductQr}
                    yes={() => (
                      <Link
                        component={RouterLink}
                        to="productlabel"
                        sx={{ color: "white" }}
                      >
                        <ListItem button onClick={() => handleMenuItemClick('productlabel')}
                          sx={{ backgroundColor: selectedItem === 'productlabel' ? 'alpha.light' : 'transparent' }}
                        >
                          <CustomListItemIcon>
                            <ProductLabelLogo />
                          </CustomListItemIcon>
                          <ListItemText primary="Product Label" />
                        </ListItem>
                      </Link>
                    )}
                    no={() => null}
                    isValidateStrict={true}
                  />
                </Typography>
              </li>
            )}
            no={() => null}
            isValidateStrict={false}
          />

          <li>
            <Typography
              sx={{
                paddingLeft: 2,
                paddingRight: 2,
                paddingTop: 3,
              }}
              onClick={() =>
                props.logout({ returnTo: process.env.REACT_APP_HOME_URL })
              }
            >
              <Link component={RouterLink} to="" sx={{ color: "white" }}>
                <CustomListItemIcon>
                  <LogoutIcon />
                </CustomListItemIcon>
                Logout
              </Link>
            </Typography>
          </li>
        </List>
      </Drawer>
    </Box>
  )
}
export default SideMenu
