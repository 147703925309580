import React, { useEffect } from 'react'
import { Box } from '@mui/material'
import { BarcodePoDetails, BarcodeRollData } from '../../types'
import './DisplayBarcodes.css'
import {Can, BackButton} from '../../../UI/Components'
import rules from '../../../Services/Authentication/rbac-rules'
import { FlatButton } from '../../../UI/Components'
import StringValues from '../../../Providers/StringValues'


const ZebraBrowserPrintWrapper = require('zebra-browser-print-wrapper')
const JsBarcode = require('jsbarcode')
interface DisplayBarcodesProps {
  poDetails : BarcodePoDetails,
  rollData: BarcodeRollData[],
  userPermissions: Array<Object>,
  handleSnackBar : (message: string) => void,
  handleSuccessBarcodePrint : () => void,
  handleBackDialog: () => void
}

function DisplayBarcodes(props: DisplayBarcodesProps) {
  const {poDetails, rollData, userPermissions, handleSnackBar, handleSuccessBarcodePrint, handleBackDialog} = props
  
  useEffect(() => {
    onNextFrame()
  }, [])

  /**
   * This function is called to wait until the browser finishes painting the current frame and is
   * ready to pain the next frame. It will then call the function passed into the callback arg
   */
  const onNextFrame = () => {
    setTimeout(() => {
      requestAnimationFrame(drawSvgs)
    }, 1)
  }
  
  /**
   * Function that is called when the browser is ready to draw the barcodes on the UI
   */
  const drawSvgs = () => {
    //  Draw the SVGs for the actual barcodes that will be displayed
    
    for (let i = 0; i < rollData.length; i++) {
      JsBarcode(`.barcode_${i}`, rollData[i].roll, {
        format: 'CODE128',
        width: 2,
        height: 100,
        displayValue: false
      })
    }

    //  Grab the array of elements that will be used to display text
    const elementsArray = [...document.getElementsByClassName('barcode-text-container')]
    elementsArray.map((element, index) => {
      element.innerHTML = `${rollData[index].roll}, ${poDetails.ocNumber}, ${rollData[index].length}, ${rollData[index].fabricWidth > 0 ? `${rollData[index].fabricWidth}",` : ''} ${poDetails.itemDescription}${rollData[index].supplierRollNo !== null ? `, ${rollData[index].supplierRollNo}` : ''}`
      return element
    })
  }
  
  
  const printBarcode = async () => {
    try {
      const browserPrint = new ZebraBrowserPrintWrapper.default()
      const defaultPrinter = await browserPrint.getAvailablePrinters()
      if (Array.isArray(defaultPrinter) === false || defaultPrinter.length === 0){
        handleSnackBar(StringValues.zebraPrinterNotFoundMessage)
        return
      }

      browserPrint.setPrinter(defaultPrinter[0])
      const printerStatus = await browserPrint.checkPrinterStatus()
      if (printerStatus.isReadyToPrint) {
        rollData.map(({ roll, length, supplierRollNo, fabricWidth }) => {
          browserPrint.print(`^XA
          ^CF0,25
          ^FO200,20,^BY3,
          ^BCN,100,Y,N,N,N
          ^FD${roll}^FS
          ^FO150,160
          ^FB600,3,,
          ^FD${length}, ${fabricWidth > 0 ? `${fabricWidth}",` : ''} ${poDetails.itemDescription}${supplierRollNo !== null ? `, ${supplierRollNo}` : ''}^FS
          ^FO60,220
          ^FDStyle: ${poDetails.styleNumber}^FS
          ^FO60,250
          ^FDShade: ^FS
          ^FO60,280
          ^FDShrinkage: ^FS
          ^FO450,220
          ^FDRelaxation ^FS
          ^FO450,250
          ^FDStart Date & Time: ^FS
          ^FO450,280
          ^FDEnd Date & Time: ^FS
          ^XZ`)
          return { roll, length, supplierRollNo }
        })
        handleSuccessBarcodePrint()

      } else {
        handleSnackBar(`Printer Error: ${printerStatus.errors}`)
        return
      }
    } catch (error) {
      handleSnackBar(`${error}`)
      return
      
    }
  }

  return (
    <React.Fragment>
      <Box  sx={{ paddingTop: 1, paddingBottom: 2}}>
        <BackButton onClick={handleBackDialog}></BackButton>
      </Box>
      
      <div className='svg_barcode_container'>
        {
          new Array(rollData.length).fill(0).map((roll, index) => {
            const svgId = `barcode_${index}`
            return (
              <div className='barcode-container' key={index}>
                <svg className={`${svgId}`}></svg>
                <p className={`barcode-text-container`} id={roll.roll}></p>
              </div>
            )
          })
        }
      </div>

      <Can
        userPermissions={userPermissions}
        action={rules.ZebraPrinter}
        yes={() => (
          <Box display="flex"
            justifyContent="center"
            alignItems="center" sx={{ paddingTop: 4 }}>
            <FlatButton label='PRINT FROM ZEBRA PRINTER' onClick={printBarcode} disableControlsStatus= {false}/>
          </Box>
        )} no={() => null} 
        isValidateStrict={true}/>
    </React.Fragment>
  )
}

export default DisplayBarcodes